<template>
  <div>
    <van-nav-bar
      title="帮扶责任人"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="countNum">
      <div>共{{ postArr.length }}人</div>
    </div>
    <div class="basicBox" v-for="(v, i) in postArr" :key="i">
      <div class="codeNum">
        <div>姓名</div>
        <div>{{ v.name }}</div>
      </div>
      <div class="codeNum">
        <div>性别</div>
        <div>{{ common.gettypeListLabelFn("sexList", v.sex) }}</div>
      </div>
      <div class="codeNum">
        <div>政治面貌</div>
        <div>
          {{ common.gettypeListLabelFn("faceList", v.politicsStatus) }}
        </div>
      </div>
      <div class="codeNum">
        <div>联系电话</div>
        <div>{{ v.phone }}</div>
      </div>
      <div class="codeNum">
        <div>所在单位</div>
        <div>{{ v.companyName }}</div>
      </div>
      <div class="codeNum">
        <div>职位</div>
        <div>
          {{ common.gettypeListLabelFn("dutyLevelList", v.dutyLevel) }}
        </div>
      </div>
      <div class="codeNum">
        <div>隶属关系</div>
        <div>
          {{ common.gettypeListLabelFn("subjectionList", v.subjection) }}
        </div>
      </div>
      <div class="codeNum">
        <div>帮扶开始时间</div>
        <div>{{ helperStartDate }}</div>
      </div>
    </div>
    <div class="opinion" @click="goOpinion">意见及诉求</div>
  </div>
</template>

<script>
import { findtroubleshootFamily, cadreManagerPage } from "@/api/atp.js"
export default {
  data () {
    return {
      postArr: [],
      searchObj: {
        idCard: JSON.parse(sessionStorage.getItem('postForm')).idCard,
      },
      helperStartDate: ''
    };
  },
  mounted () {
    this.getAll();
  },
  methods: {
    getAll () {
      findtroubleshootFamily(this.searchObj)
        .then((res) => {
          if (res.status === 200) {
            this.helperStartDate = res.data.helperStartDate
            let helperId = res.data.helperId
            if (helperId === null) {
              this.$toast("暂无帮扶责任人信息");
            } else {
              let obj = {
                helperId
              }
              cadreManagerPage(obj)
                .then((res) => {
                  if (res.status === 200) {
                    this.postArr = res.data.content
                  } else {
                    this.$toast("查询帮扶责任人失败");
                  }
                })
                .catch(() => {
                  this.$toast("获取帮扶责任人信息失败");
                });
            }
          } else {
            this.$toast("查询数据失败");
          }
        })
        .catch(() => {
          this.$toast("获取信息失败");
        });
    },
    onClickLeft () {
      this.$router.go(-1);
    },
    goOpinion () {
      this.$router.push("/opinion");
    },
  },
};
</script>

<style scoped>
.opinion {
  width: 100%;
  height: 0.4rem;
  line-height: 0.4rem;
  background-color: #f0ffff;
  color: #1e90ff;
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
}
/* vant 导航栏自适应 */
.van-nav-bar {
  height: 0.46rem;
}
 .van-nav-bar__content {
  height: 100%;
}
 .van-nav-bar__title {
  height: 100%;
  line-height: 0.46rem;
  font-size: 0.16rem;
}
 .van-icon-arrow-left,
 .van-nav-bar__text {
  font-size: 0.14rem;
}
.basicBox {
  margin-bottom: 0.5rem;
  border-bottom: 4px solid #eee;
}
.basicBox > div {
  border-bottom: 1px solid #eee;
  width: 94%;
  height: 0.5rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.basicBox > div > div {
  line-height: 0.5rem;
}
.countNum {
  width: 94%;
  height: 0.5rem;
  line-height: 0.5rem;
  margin: 0 auto;
  border-bottom: 3px solid #eee;
}
</style>